import React from 'react'
import { graphql } from 'gatsby'
import { getSrc, getImage, GatsbyImage } from 'gatsby-plugin-image'
import Layout from '@/shared/globalStyle'
import SEO from '@/shared/seo'
import PostCss from '@/shared/PostCss'
import Wrapper from '../atoms/util/Wrapper'
import 'twin.macro'

interface ArticleTemplate {
  data: {
    markdownRemark: {
      id: string
      excerpt: string
      html: string
      frontmatter: {
        title: string
        description: string
        thumbnail: any
      }
    }
  }
}

const PostPage: React.FC<ArticleTemplate> = ({ data }) => {
  const { title, description, thumbnail } = data.markdownRemark.frontmatter
  const src = getSrc(thumbnail)
  const image = getImage(thumbnail)
  return (
    <>
      <SEO lang="ja" title={title} description={description} image={src} />
      <Layout>
        <Wrapper>
          <h1 tw="lg:pt-36 pt-24 lg:text-3xl text-2xl leading-8 tracking-wider">
            {title}
          </h1>
          <GatsbyImage
            image={image}
            alt="アイキャッチ"
            tw="lg:my-8 my-4 max-h-96"
            objectFit="contain"
          />
          <section
            tw="lg:pb-24 pb-12"
            css={PostCss}
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </Wrapper>
      </Layout>
    </>
  )
}

export default PostPage

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
