import tw, { css } from 'twin.macro'

const PostCss = css`
  h2 {
    &::before {
      ${tw`block w-2 bg-blue-400 h-3/5 absolute left-0`}
      content: ''
    }
    ${tw`lg:text-2xl leading-6 tracking-wider text-xl pl-4 py-2.5 font-medium relative`};
  }
  p {
    ${tw`lg:text-lg leading-6 tracking-wider py-1`}
  }
`

export default PostCss
